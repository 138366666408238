import Router from 'vue-router';
import Vue from 'vue';

Vue.use(Router)
const vueRouter = new Router({
    mode: 'history',
    // // 内网打包
    // base: '/screen',
    // // 
    routes: [{
        path: '/',
        name: 'main',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/page/index')
    }, {
        path: '/build/:id',
        name: 'build',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/page/build')
    }, {
        path: '/view/:id',
        name: 'view',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/page/view')
    }, {
        path: '/home',
        name: 'home',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/page/home/home')
    }, {
        path: '/404',
        component: () => import(/* webpackChunkName: "fail" */ '@/page/exception/404')
    }, {
        path: '/403',
        component: () => import(/* webpackChunkName: "fail" */ '@/page/exception/403')
    }, {
        path: '/login',
        name: 'login',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/page/login/login')
    }, {
        path: '*', redirect: '/404', hidden: true
    }]
})
export default vueRouter;
