<template>
  <!--  3D 面积图-->
  <div :style="{width: width, height: height}">
    <Base :id="id" :width=width :height="height" :option="options"></Base>
  </div>
</template>

<script>
import Base from './base'

export default {
  name: "area3d",
  components: {Base},
  props: {
    id: String,
    component: Object,
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    width() {
      return (this.component.width || 600) + 'px'
    },
    height() {
      return (this.component.height || 400) + 'px'
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: 'area',
          backgroundColor: 'rgba(0,0,0,0.01)',
          style: {
            fontSize: '32px',
            color: '#ffffff'
          },
          options3d: {
            enabled: true,
            alpha: 15,
            beta: 30,
            depth: 200
          }
        },
        title: {
          text: "3D 面积图",
          style: {
            "fontSize": "20px",
            "color": '#ffffff'
          }
        },
        yAxis: {
          title: {
            text: '',
            x: -40,
          },
          labels: {
            format: '{value:,.0f} MAMSL'
          },
          gridLineDashStyle: 'Dash'
        },
        xAxis: [{
          visible: false
        }, {
          visible: false
        }, {
          visible: false
        }],
        plotOptions: {
          area: {
            depth: 100,
            marker: {
              enabled: false
            },
            states: {
              inactive: {
                enabled: false
              }
            }
          }
        },
        tooltip: {
          valueSuffix: ' MAMSL'
        },
        series: [
          {
            name: "Tatra Mountains visible from Rusinowa polana",
            lineColor: 'rgb(180,90,50)',
            color: 'rgb(200,110,50)',
            fillColor: 'rgb(200,110,50)',
            data: [
              ['Murań', 1890],
              ['Nowy Wierch', 2009],
              ['Hawrań', 2152],
              ['Płaczliwa Skała', 2142],
              ['Szalony Wierch', 2061],
              ['Karczmarski Wierch', 1438]
            ]
          },
          {
            xAxis: 1,
            lineColor: 'rgb(120,160,180)',
            color: 'rgb(140,180,200)',
            fillColor: 'rgb(140,180,200)',
            name: "Dachstein panorama seen from Krippenstein",
            data: [
              ['Kufstein', 2049],
              ['Hohe Wildstelle', 2746],
              ['Kleiner Miesberg', 2173],
              ['Großer Miesberg', 2202],
              ['Hochstein', 2543],
              ['Lackner Miesberg', 2232]
            ]
          },
        ]
      }
    }
  },
  watch: {
    option: {
      handler(value) {
        console.log(value)
        this.options.chart.backgroundColor = value.bgColor
        this.options.title.style.fontSize = (value.titleFontSize || 20) + 'px'
        this.options.title.style.color = value.titleColor || '#ffffff'
        this.options.plotOptions.pie.innerSize = value.innerSize || 200
        this.options.chart.options3d.alpha = value.alpha || 50
        this.options.plotOptions.pie.depth = value.depth || 60
      },
      deep: true
    },
    dataChart: {
      handler(value) {
        if(Array.isArray(value) && value.length){
          this.options.series = value
          this.options = JSON.parse(JSON.stringify(this.options))
        }
      },
      deep: true
    }
  },
}
</script>

<style>

</style>
