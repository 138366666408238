// 自定义接口
import { url, lowCodeUrl } from '@/config';
import request from '../axios'

/**
 * 获取地图列表
 * @param params
 * @returns {AxiosPromise}
 */
export const getList = (params) => request({
    url: url + '/map/list',
    method: 'get',
    params: params
});

/**
 * 获取当前对象children
 *  pid 为当前对象id
 *  一级对象pid传0
 *  /screen/bcScreenSettings/children?pid=${pid}
 * @param pid
 * @returns {AxiosPromise}
 */
export const getChildrenList = (pid) => request({
    url: lowCodeUrl + '/screen/bcScreenSettings/children',
    // // 内网打包
    // url: '/web/lowcode/screen/bcScreenSettings/children',
    // // 
    method: 'get',
    params: {
        pid
    }
});

export const getAllInfoList = (params) => request({
    url: lowCodeUrl + '/screen/bcScreenSettings/allInfo',
    // // 内网打包
    // url: '/web/lowcode/screen/bcScreenSettings/allInfo',
    // // 
    method: 'get',
    params
});


// 获取树结构
/**
 * 获取所有树
 * name为当前大屏名称
 * 如不传name则返回的所有对象的checked字段为false
 * 若传入name则对应大屏对象的checked字段为true
 * /screen/bcScreenSettings/tree?name=${name}
 * @param params
 * @returns {AxiosPromise}
 */
export const getAllList = (params) => request({
    url: lowCodeUrl + '/screen/bcScreenSettings/tree',
    // // 内网打包
    // url: '/web/lowcode/screen/bcScreenSettings/tree',
    // // 
    method: 'get',
    params
});

/**
 * 自定义接口，传全部的url。http://......
 * @param urlParams
 * @returns {AxiosPromise}
 */
export const customApi = (urlParams) => request({
    url: urlParams,
    method: 'get'
});

export const getWelcomeToLanguage = () => request({
    url: lowCodeUrl + '/lowcode/online/cgform/api/getData/a19e43ba771640569335ad4945925960',
    // // 内网打包
    // url: '/web/lowcode/screen/bcScreenSettings/children',
    // // 
    method: 'get',
    params: {
        _t: 1664182678,
        configurationpage: "首页",
        pageNo: 1,
        pageSize: 10,
    }
});




