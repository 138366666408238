import highCharts from './index.vue'
// 3d饼图
import hcPie3d from './pie3d'
// 3d环形饼图
import hcRingDiagram3d from './ringDiagram3d'
// 3d散点图
import scatter3d from './scatter3d'
// 3d圆柱图
import cylinder3d from './cylinder3d';
import area3d from './area3d';
import pyramid3d from './pyramid3d';
import funnel3d from './funnel3d';
import column3d from './column3d';
import columnStack3d from './columnStack3d';

export const highchartsLists = [
  highCharts,
  column3d,
  columnStack3d,
  funnel3d,
  pyramid3d,
  hcPie3d,
  hcRingDiagram3d,
  scatter3d,
  cylinder3d,
  area3d,
]
