<template>
  <!--  环形3d饼图-->
  <div :style="{width: width, height: height}">
    <Base :id="id" :width=width :height="height" :option="options"></Base>
  </div>
</template>

<script>
import Base from './base'

export default {
  name: "hcRingDiagram3d",
  components: {Base},
  props: {
    id: String,
    component: Object,
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    width() {
      return (this.component.width || 600) + 'px'
    },
    height() {
      return (this.component.height || 400) + 'px'
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: 'pie',
          backgroundColor: 'rgba(0,0,0,0.01)',
          style: {
            "fontSize": "32px",
            color: '#ffffff'
          },
          options3d: {
            enabled: true,
            alpha: 50
          }
        },
        labels: {
          style: {
            "fontSize": "32px",
            color: '#ffffff'
          }
        },
        title: {
          text: '3D 环形饼图',
          style: {
            "fontSize": "20px",
            "color": '#ffffff'
          }
        },
        plotOptions: {
          pie: {
            innerSize: 200,
            depth: 60
          }
        },
        series: []
      }
    }
  },
  watch: {
    option: {
      handler() {
        this.changeOptions()
      },
      deep: true
    },
    dataChart: {
      handler() {
        this.changeData()
      },
      deep: true
    }
  },
  created() {
    this.changeOptions()
    this.changeData()
  },
  methods: {
    // 参数改变
    changeOptions() {
      const value = this.option
      this.options.chart.backgroundColor = value.bgColor
      this.options.title.style.fontSize = (value.titleFontSize || 20) + 'px'
      this.options.title.style.color = value.titleColor || '#ffffff'
      this.options.plotOptions.pie.innerSize = value.innerSize || 200
      this.options.chart.options3d.alpha = value.alpha || 50
      this.options.plotOptions.pie.depth = value.depth || 60
    },
    // 数据改变
    changeData() {
      this.options.series[0] = this.dataChart
      this.options = JSON.parse(JSON.stringify(this.options))
    }
  }
}
</script>

<style>

</style>
