<template>
  <!--  3d散点图-->
  <div :style="{width: width, height: height}">
    <Base :id="id" :width=width :height="height" :option="options"></Base>
  </div>
</template>

<script>
import Base from './base'

export default {
  name: "scatter3d",
  components: {Base},
  props: {
    id: String,
    component: Object,
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    width() {
      return (this.component.width || 600) + 'px'
    },
    height() {
      return (this.component.height || 400) + 'px'
    }
  },
  data() {
    return {
      options: {
        chart: {
          renderTo: 'container',
          margin: 100,
          type: 'scatter',
          backgroundColor: 'rgba(0,0,0,0.01)',
          style: {
            fontSize: '32px',
            color: '#ffffff'
          },
          options3d: {
            enabled: true,
            alpha: 10,
            beta: 30,
            depth: 250,
            viewDistance: 5,
            frame: {
              bottom: {size: 1, color: 'rgba(0,0,0,0.02)'},
              back: {size: 1, color: 'rgba(0,0,0,0.04)'},
              side: {size: 1, color: 'rgba(0,0,0,0.06)'}
            }
          }
        },
        title: {
          text: '3D散点图',
          style: {
            "fontSize": "20px",
            "color": '#ffffff'
          }
        },
        plotOptions: {
          scatter: {
            width: 10,
            height: 10,
            depth: 10
          }
        },
        yAxis: {
          min: 0,
          max: 10,
          title: null
        },
        xAxis: {
          min: 0,
          max: 10,
          gridLineWidth: 1
        },
        zAxis: {
          min: 0,
          max: 10
        },
        legend: {
          enabled: false
        },
        series: [
          {
            name: '随机数据',
            colorByPoint: true,
            data: [[5, 2, 10], [6, 2, 10], [7, 2, 10]]
          }
        ]
      }
    }
  },
  watch: {
    option: {
      handler(value) {
        console.log(value)
        this.options.chart.backgroundColor = value.bgColor
        this.options.title.style.fontSize = (value.titleFontSize || 20) + 'px'
        this.options.title.style.color = value.titleColor || '#ffffff'
        this.options.plotOptions.pie.innerSize = value.innerSize || 200
        this.options.chart.options3d.alpha = value.alpha || 50
        this.options.plotOptions.pie.depth = value.depth || 60
      },
      deep: true
    },
    dataChart: {
      handler(value) {
        this.options.series[0] = value
        this.options = JSON.parse(JSON.stringify(this.options))
      },
      deep: true
    }
  },
}
</script>

<style>

</style>
