<template>
  <div
    :style="{
      backgroundColor: backgroundColor,
      textAlign: textAlign,
      height: height,
    }"
  >
    <a
      :href="linkHref"
      :style="{
        fontSize: fontSize,
        letterSpacing: letterSpacing,
        color: color,
        lineHeight: lineHeightS,
        fontWeight: fontWeight,
      }"
      :target="linkTarget"
      >{{ data1.value ? data1.value : data.value }}</a
    >
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "text1",
  props: {
    data: Object,
    dataChart: Object,
    option: Object,
    component: Object,
  },
  computed: {
    height() {
      console.log(this.component);
      return this.component.height || "transparent";
    },
    backgroundColor() {
      console.log(this.component);
      return this.option.backgroundColor || "transparent";
    },
    fontSize() {
      console.log(this.component);
      return (this.option.fontSize || 30) + "px";
    },
    letterSpacing() {
      return (this.option.split || 0) + "px";
    },
    color() {
      // const maxValue = this.data1.value2 ? this.data1.value2 : this.data.value2
      const value = this.data1.value ? this.data1.value : this.data.value;
      // return  parseInt(value + '')>= parseInt(maxValue + '') ? 'red' : this.option.color
      return parseInt(value + "") > parseInt(this.option.KuoValue + "")
        ? "red"
        : this.option.color;
    },
    lineHeight() {
      return (this.component.height || 0) + "px";
    },
    lineHeightS() {
      return (this.option.lineHeight || 0) + "px";
    },
    fontWeight() {
      return this.option.fontWeight || "normal";
    },
    textAlign() {
      return this.option.textAlign || "left";
    },
    linkHref() {
      return this.option.link ? this.option.linkHref : "#";
    },
    linkTarget() {
      return this.option.linkTarget || "_self";
    },
  },
  data() {
    return {
      data1: {
        value: null,
        value2: null,
      },
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      const that = this;
      console.log(this.$attrs.dataMethod);
      console.log(this.$attrs.url);
      console.log(this.$attrs);
      if (this.$attrs.dataMethod == "get") {
        axios.get(this.$attrs.url).then((res) => {
          const { data } = res;
          that.data1["value"] = data.value;
          that.data1["value2"] = data.value2;
          that.data1 = JSON.parse(JSON.stringify(that.data1));
        });
      } else {
        console.log(this.$attrs.dataQuery)
        axios.post(this.$attrs.url, this.$attrs.dataQuery).then((res) => {
          console.log(res);
          const { data } = res;
          that.data1["value"] = data.value;
          that.data1["value2"] = data.value2;
          that.data1 = JSON.parse(JSON.stringify(that.data1));
        });
      }
    },
  },
};
</script>
<style scoped>
div a {
  display: inline-block;
  width: 100%;
}
</style>

