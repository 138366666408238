<template>
  <div v-if="option.backgroundImage">
    <div class="item-box"
         v-for="item in data"
         :key="item.value"
         @click="changeType(item)"
         :style="{
           'background-image': dataType === item.value ? 'url('+  option.empBackgroundImage +')':'url('+  option.backgroundImage +')',
           'height': itemHeight + 'px',
           'lineHeight': itemHeight + 'px',
           'fontSize': option.fontSize + 'px',
           'color': option.color}">
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabsVertical",
  props: {
    // backgroundImage: "/img/banner/banner4.png"
    // color: "#eee"
    // empBackgroundImage: "/img/banner/banner5.png"
    // empColor: "#4dffff"
    // fontSize: 20
    // split: 8
    // type: "tabs"
    option: {
      type: Object,
      default: () => {
        return {}
      }
    },
    child: {
      type: Object,
      default: () => {
        return {}
      }
    },
    component: {
      type: Object,
      default: () => {
        return {}
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    // dataType: {
    //   type: Number,
    //   default: 0
    // },
  },
  data() {
    return {
      itemHeight: 20, // 一个item高
      dataType: 0
    }
  },
  watch: {
    option: {
      handler(value) {
      },
      deep: true
    },
    child: {
      handler(value) {
      },
      deep: true
    },
    component: {
      handler(value) {
        this.computeItemHeight()
      },
      deep: true
    },
    data: {
      handler(value) {
      },
      deep: true
    },
    dataType: {
      handler(value) {
        console.log(value)
      },
      deep: true
    }
  },
  created() {
    this.dataType = this.data[0].value
    this.computeItemHeight()
    // console.log(this.$parent.$parent.nav)
    // console.log( window.__container.contain.nav[0].display=true)
  },
  methods:{
    computeItemHeight(){
      const contentHeight = this.component.height - 4 * this.data.length
      this.itemHeight = (contentHeight / this.data.length).toFixed(2)
    },
    changeType(item){
      let prefix = item.value.split("-");
      let arrS = window.__container.contain.list
      let all = arrS.filter(item => item.name.startsWith(prefix[0]+"-"))
      all.forEach(innerItem =>{
        innerItem.display = innerItem.name !== item.value;
      })
      this.dataType = item.value
    }
  }
}
</script>

<style scoped>
.item-box {
  margin-bottom: 4px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
</style>
