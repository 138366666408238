var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    backgroundColor: _vm.backgroundColor,
    textAlign: _vm.textAlign,
    height: _vm.height,
  })},[_c('a',{style:({
      fontSize: _vm.fontSize,
      letterSpacing: _vm.letterSpacing,
      color: _vm.color,
      lineHeight: _vm.lineHeightS,
      fontWeight: _vm.fontWeight,
    }),attrs:{"href":_vm.linkHref,"target":_vm.linkTarget}},[_vm._v(_vm._s(_vm.data1.value ? _vm.data1.value : _vm.data.value))])])}
var staticRenderFns = []

export { render, staticRenderFns }