<template>
  <!--  3D 柱状图-->
  <div :style="{width: width, height: height}">
    <Base :id="id" :width=width :height="height" :option="options"></Base>
  </div>
</template>

<script>
import Base from './base'
import Highcharts from 'highcharts'

export default {
  name: "column3d",
  components: {Base},
  props: {
    id: String,
    component: Object,
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    width() {
      return (this.component.width || 600) + 'px'
    },
    height() {
      return (this.component.height || 400) + 'px'
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: 'column',
          backgroundColor: 'rgba(0,0,0,0.01)',
          style: {
            fontSize: '32px',
            color: '#ffffff'
          },
          margin: 75,
          options3d: {
            enabled: true,
            alpha: 10,
            beta: 25,
            depth: 70,
            viewDistance: 100,      // 视图距离，它对于计算角度影响在柱图和散列图非常重要。此值不能用于3D的饼图
            frame: {                // Frame框架，3D图包含柱的面板，我们以X ,Y，Z的坐标系来理解，X轴与 Z轴所形成
              // 的面为bottom，Y轴与Z轴所形成的面为side，X轴与Y轴所形成的面为back，bottom、
              // side、back的属性一样，其中size为感官理解的厚度，color为面板颜色
              bottom: {
                size: 10
              },
              side: {
                size: 1,
                color: 'transparent'
              },
              back: {
                size: 1,
                color: 'transparent'
              }
            }
          },
        },
        title: {
          text: '3D 柱状图',
          style: {
            "fontSize": "20px",
            "color": '#ffffff'
          }
        },
        plotOptions: {
          column: {
            depth: 25
          }
        },
        xAxis: {
          categories: Highcharts.getOptions().lang.shortMonths
        },
        yAxis: {
          title: {
            text: null
          }
        },
        series: [
          {
            name: '苹果',
            data: [2, 3, null, 4, 0, 5, 1, 4, 6, 3]
          },
          {
            name: '香蕉',
            data: [2, 3, null, 4, 0, 5, 1, 4, 6, 3]
          }
        ]
      }
    }
  },
  watch: {
    option: {
      handler(value) {
        console.log(value)
        this.options.chart.backgroundColor = value.bgColor
        this.options.title.style.fontSize = (value.titleFontSize || 20) + 'px'
        this.options.title.style.color = value.titleColor || '#ffffff'
        this.options.plotOptions.pie.innerSize = value.innerSize || 200
        this.options.chart.options3d.alpha = value.alpha || 50
        this.options.plotOptions.pie.depth = value.depth || 60
      },
      deep: true
    },
    dataChart: {
      handler(value) {
        if(Array.isArray(value) && value.length){
          this.options.series = value
          this.options = JSON.parse(JSON.stringify(this.options))
        }
      },
      deep: true
    }
  },
}
</script>

<style>

</style>
