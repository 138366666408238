<template>
  <div :style="{width: width, height: height}">
    <Base :id="id" :width=width :height="height" :option="options"></Base>
  </div>
</template>

<script>
import Base from './base'

export default {
  name: "highCharts",
  components: {Base},
  props: {
    id: String,
    component: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    width() {
      return (this.component.width || 600) + 'px'
    },
    height() {
      return (this.component.height || 400) + 'px'
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: 'pie',
          backgroundColor: 'rgba(0,0,0,0.01)',
          style: {
            "fontSize": "32px",
            color: '#ffffff'
          },
          options3d: {
            enabled: true,
            alpha: 50
          }
        },
        labels: {
          style: {
            "fontSize": "32px",
            color: '#ffffff'
          }
        },
        title: {
          text: '每周水果消耗量',
          style: {
            "fontSize": "20px",
            "color": '#ffffff'
          }
        },
        plotOptions: {
          pie: {
            innerSize: 200,
            depth: 60
          }
        },
        series: [
          {
            name: '货物金额',
            data: [
              ['苹果', 8],
              ['柑橘', 4],
              ['橙子', 1]
            ]
          }
        ]
      }
    }
  },
  watch: {
    dataChart: {
      handler(value) {
        const res = Object.prototype.toString.call(value)
        if (res === '[object Object]' && Object.keys(value).length) {
          this.options = JSON.parse(JSON.stringify(value))
        }
      },
      deep: true
    }
  },
}
</script>

<style>

</style>
