/**
 * 自定义组件参考文档
 * https://cn.vuejs.org/v2/guide/components-registration.html
*/
import Vue from 'vue'
import Test from './test/';
import TabsVertical from './tabs-vertical/index';
import TabsVerticalRoll from './tabs-vertical/index-roll'; // 可滚动
import Back from './back/' // 返回控件
import Menu from './menu/' // 菜单
import RingDiagram from './ring-diagram/' // 3D柱形图
import Text1 from './text/'
import Iframe from './iframes/'
import {highchartsLists} from '@/highcharts/index'

const list = [
  Test,
  Back,
  Menu,
  RingDiagram,
  TabsVertical,
  TabsVerticalRoll,
  Text1,
  Iframe,
  ...highchartsLists,
]
//循环注册组件
list.forEach(ele => {
  Vue.component(`geekmore-echart-${ele.name}`, ele)
})
