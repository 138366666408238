<template>
  <!--  3d圆柱图-->
  <div :style="{width: width, height: height}">
    <Base :id="id" :width=width :height="height" :option="options"></Base>
  </div>
</template>

<script>
import Base from './base'

export default {
  name: "cylinder3d",
  components: {Base},
  props: {
    id: String,
    component: Object,
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    width() {
      return (this.component.width || 600) + 'px'
    },
    height() {
      return (this.component.height || 400) + 'px'
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: 'cylinder',
          backgroundColor: 'rgba(0,0,0,0.01)',
          style: {
            fontSize: '32px',
            color: '#ffffff'
          },
          options3d: {
            enabled: true,
            alpha: 15,
            beta: 15,
            depth: 50,
            viewDistance: 25
          }
        },
        title: {
          text: '3D 圆柱图',
          style: {
            "fontSize": "20px",
            "color": '#ffffff'
          }
        },
        plotOptions: {
          series: {
            depth: 25,
            colorByPoint: true
          }
        },
        series: [{
          data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
          name: 'Cylinders',
          showInLegend: false
        }]
      }
    }
  },
  watch: {
    option: {
      handler(value) {
        console.log(value)
        this.options.chart.backgroundColor = value.bgColor
        this.options.title.style.fontSize = (value.titleFontSize || 20) + 'px'
        this.options.title.style.color = value.titleColor || '#ffffff'
        this.options.plotOptions.pie.innerSize = value.innerSize || 200
        this.options.chart.options3d.alpha = value.alpha || 50
        this.options.plotOptions.pie.depth = value.depth || 60
      },
      deep: true
    },
    dataChart: {
      handler(value) {
        this.options.series[0] = value
        this.options = JSON.parse(JSON.stringify(this.options))
      },
      deep: true
    }
  },
}
</script>

<style>

</style>
