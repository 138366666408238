<template>
  <!--  3D 柱状图-->
  <div :style="{width: width, height: height}">
    <Base :id="id" :width=width :height="height" :option="options"></Base>
  </div>
</template>

<script>
import Base from './base'

export default {
  name: "columnStack3d",
  components: {Base},
  props: {
    id: String,
    component: Object,
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    width() {
      return (this.component.width || 600) + 'px'
    },
    height() {
      return (this.component.height || 400) + 'px'
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: 'column',
          backgroundColor: 'rgba(0,0,0,0.01)',
          style: {
            fontSize: '32px',
            color: '#ffffff'
          },
          options3d: {
            enabled: true,
            alpha: 15,
            beta: 15,
            viewDistance: 25,
            depth: 40
          },
          marginTop: 80,
          marginRight: 40
        },
        title: {
          text: '以性别划分的水果消费总量',
          style: {
            "fontSize": "20px",
            "color": '#ffffff'
          }
        },
        xAxis: {
          categories: ['苹果', '橘子', '梨', '葡萄', '香蕉']
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: '水果数量',
            style: {
              "fontSize": "20px",
              "color": '#ffffff'
            }
          }
        },
        tooltip: {
          headerFormat: '<b>{point.key}</b><br>',
          pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            depth: 40
          }
        },
        series: [{
          name: '小张',
          data: [5, 3, 4, 7, 2],
          stack: 'male'
        }, {
          name: '小王',
          data: [3, 4, 4, 2, 5],
          stack: 'male'
        }, {
          name: '小彭',
          data: [2, 5, 6, 2, 1],
          stack: 'female'
        }, {
          name: '小潘',
          data: [3, 0, 4, 4, 3],
          stack: 'female'
        }]
      }
    }
  },
  watch: {
    option: {
      handler(value) {
        console.log(value)
        this.options.chart.backgroundColor = value.bgColor
        this.options.title.style.fontSize = (value.titleFontSize || 20) + 'px'
        this.options.title.style.color = value.titleColor || '#ffffff'
        this.options.plotOptions.pie.innerSize = value.innerSize || 200
        this.options.chart.options3d.alpha = value.alpha || 50
        this.options.plotOptions.pie.depth = value.depth || 60
      },
      deep: true
    },
    dataChart: {
      handler(value) {
        this.options.series[0] = value
        this.options = JSON.parse(JSON.stringify(this.options))
      },
      deep: true
    }
  },
}
</script>

<style>

</style>
