export default {
  COMPNAME: "geekmore-echart-",
  NAME: "list",
  DEAFNAME: 'item'
}
let url1 = '/api/blade-visual'
if (process.env.NODE_ENV === 'development') {
  url1 = 'http://toccscreen.geek-more.com/api/blade-visual'
}
export const url = url1

let url2 = 'http://toccweb.geek-more.com/lowcode/'
if (process.env.NODE_ENV === 'development') {
  url2 = 'http://toccweb.geek-more.com/lowcode/'
}
export const lowCodeUrl = url2

// // 内网打包
// export default {
//   COMPNAME: "geekmore-echart-",
//   NAME: "list",
//   DEAFNAME: 'item'
// }
// let url1 = '/screen/api/blade-visual'
// if (process.env.NODE_ENV === 'development') {
//   url1 = '/screen/api/blade-visual'
// }
// export const url = url1

// let url2 = '/web/lowcode/'
// if (process.env.NODE_ENV === 'development') {
//   url2 = '/web/lowcode/'
// }
// export const lowCodeUrl = url2
// // 