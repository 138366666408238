<template>
  <div :style="{width: width, height: height}">
    <HighChartsBase :id="id" :width=width :height="height" :option="options"></HighChartsBase>
  </div>
</template>

<script>
import HighChartsBase from './high-charts-base'

export default {
  name: "RingDiagram",
  components: {HighChartsBase},
  props: {
    id: String,
    component: Object,
    option: Object
  },
  computed: {
    width() {
      return (this.component.width || 600) + 'px'
    },
    height() {
      return (this.component.height || 400) + 'px'
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: 'pie',
          backgroundColor: 'rgba(0,0,0,0.01)',
          style: {
            "fontSize": "32px",
            color: '#ffffff'
          },
          options3d: {
            enabled: true,
            alpha: 50
          }
        },
        labels: {
          style: {
            "fontSize": "32px",
            color: '#ffffff'
          }
        },
        title: {
          text: '每周水果消耗量',
          style: {
            "fontSize": "20px",
            "color": '#ffffff'
          }
        },
        plotOptions: {
          pie: {
            innerSize: 200,
            depth: 60
          }
        },
        series: [
          {
            name: '货物金额',
            style: {
              "fontSize": "32px",
              color: '#ffffff'
            },
            data: [
              ['香蕉', 8],
              ['猕猴桃', 3],
              ['桃子', 1],
              ['橘子', 6],
              ['苹果', 8]
            ]
          }
        ]
      }
    }
  },
  created() {
    this.changeOptions(this.option)
  },
  watch: {
    option: {
      handler(value) {
        console.log(value)
        this.changeOptions()
      },
      deep: true
    }
  },
  methods: {
    changeOptions(value) {
      this.options.chart.backgroundColor = value.bgColor
      this.options.title.style.fontSize = (value.titleFontSize || 20) + 'px'
      this.options.title.style.color = value.titleColor || '#ffffff'
      this.options.plotOptions.pie.innerSize = value.innerSize || 200
      this.options.chart.options3d.alpha = value.alpha || 50
      this.options.plotOptions.pie.depth = value.depth || 60
    }
  }
}
</script>

<style>
rect {
  background-color: blue !important;
}
</style>
