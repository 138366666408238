
<template>
  <div class="back">
    <div>
      <img
        :style="{ width: imgWidth, height: imgHeight }"
        :src="option.backgroundImage"
        alt=""
        @click="handleOpen"
      />
    </div>
    <el-drawer
      :visible.sync="drawer"
      :direction="direction"
      :append-to-body="true"
      :close-on-press-escape="true"
      :modal="false"
      :with-header="false"
      :size="drawerWidth"
      :before-close="handleClose"
    >
      <div
        class="drawer-box"
        :style="{ backgroundColor: drawerBGColor, color: drawerTextColor }"
      >
        <div
          class="button-group"
          :style="{
            fontSize: fontSize,
            color: color,
            letterSpacing: letterSpacing,
          }"
        >
          <div
            class="button-item button-back"
            :style="{ width: itemWidth }"
            @click="goBack"
          >
            <img
              :style="{ width: iconSize, height: iconSize }"
              src="../../../public/icon/back.png"
              alt=""
            />
            <span>{{ backText }}</span>
          </div>
          <div
            class="button-item"
            :style="{ width: itemWidth }"
            @click="goHome"
          >
            <img
              :style="{ width: iconSize, height: iconSize }"
              src="/icon/menu.png"
              alt=""
            />
            <!-- 内网打包 -->
            <!-- <img
              :style="{ width: iconSize, height: iconSize }"
              src="/screen/icon/menu.png"
              alt=""
            /> -->
            <!--  -->
            <span>{{ menuText }}</span>
          </div>
        </div>

        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @select="handleMenuSelect"
          @open="handleMenuOpen"
          @close="handleMenuClose"
          background-color="rgba(14,22,75,0.8)"
          text-color="#fff"
          :unique-opened="true"
          active-text-color="skyblue"
        >
          <div v-for="item in menuList" :key="item.id">
            <el-submenu
              v-if="item.children && item.children.length"
              :index="item.id"
            >
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <div v-for="itemTwo in item.children" :key="itemTwo.id">
                <el-submenu
                  v-if="itemTwo.children && itemTwo.children.length"
                  :index="itemTwo.id"
                >
                  <template slot="title">{{ itemTwo.name }}</template>
                  <div
                    v-for="itemThree in itemTwo.children"
                    :key="itemThree.id"
                  >
                    <el-submenu
                      v-if="itemThree.children && itemThree.children.length"
                      :index="itemThree.id"
                    >
                      <template slot="title">{{ itemThree.name }}</template>
                    </el-submenu>
                    <el-menu-item v-else :index="itemThree.id">
                      <span slot="title">{{ itemThree.name }}</span>
                    </el-menu-item>
                  </div>
                </el-submenu>
                <el-menu-item v-else :index="itemTwo.id">
                  <span slot="title">{{ itemTwo.name }}</span>
                </el-menu-item>
              </div>
            </el-submenu>
            <el-menu-item v-else :index="item.id">
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { customApi, getChildrenList, getAllList } from "../../api/custom";

export default {
  name: "menu",
  props: {
    option: Object,
    component: Object,
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      drawer: false,
      direction: "ltr",
      clickId: null,
      clickItem: null,
      menuList: [
        {
          id: "1481881426598240258",
          createBy: "admin",
          createTime: "2022-01-14 14:50:38",
          updateBy: null,
          updateTime: null,
          sysOrgCode: "A01",
          name: "行业综合监管系统",
          classifyName: null,
          url: null,
          screenCode: null,
          note: null,
          pid: "0",
          icon: null,
          children: [
            {
              id: "1481881582060118018",
              createBy: "admin",
              createTime: "2022-01-14 14:51:15",
              updateBy: null,
              updateTime: null,
              sysOrgCode: "A01",
              name: "总体运行状态可视化",
              classifyName: null,
              url: null,
              screenCode: null,
              note: null,
              pid: "1481881426598240258",
              icon: null,
              children: [
                {
                  id: "1480799602807611393",
                  createBy: "admin",
                  createTime: "2022-01-11 15:11:52",
                  updateBy: null,
                  updateTime: null,
                  sysOrgCode: "A01",
                  name: "路网总体运行状态可视化",
                  classifyName: "总体运行状态可视化",
                  url: "http://toccscreen.geek-more.com//view/72",
                  screenCode: 72,
                  note: null,
                  pid: "1481881582060118018",
                  icon: null,
                  children: [],
                  checked: true,
                },
                {
                  id: "1480799716389363713",
                  createBy: "admin",
                  createTime: "2022-01-11 15:12:19",
                  updateBy: null,
                  updateTime: null,
                  sysOrgCode: "A01",
                  name: "水网运行状态",
                  classifyName: "总体运行状态可视化",
                  url: "http://toccscreen.geek-more.com//view/73",
                  screenCode: 73,
                  note: null,
                  pid: "1481881582060118018",
                  icon: null,
                  children: [],
                  checked: false,
                },
                {
                  id: "1480799873549934593",
                  createBy: "admin",
                  createTime: "2022-01-11 15:12:56",
                  updateBy: null,
                  updateTime: null,
                  sysOrgCode: "A01",
                  name: "交通指标",
                  classifyName: "总体运行状态可视化",
                  url: "http://toccscreen.geek-more.com//view/76",
                  screenCode: 76,
                  note: null,
                  pid: "1481881582060118018",
                  icon: null,
                  children: [],
                  checked: false,
                },
              ],
              checked: false,
            },
          ],
          checked: false,
        },
      ],
    };
  },
  computed: {
    itemWidth() {
      return (this.option.backWidth || 80) + "px";
    },
    imgWidth() {
      return (this.component.width || 84) + "px";
    },
    imgHeight() {
      return (this.component.height || 84) + "px";
    },
    iconSize() {
      return (this.option.iconSize || 20) + "px";
    },
    fontSize() {
      return (this.option.fontSize || 30) + "px";
    },
    color() {
      return this.option.color || "#4383FF";
    },
    letterSpacing() {
      return (this.option.split || 2) + "px";
    },
    backText() {
      return this.option.backText;
    },
    menuText() {
      return this.option.menuText;
    },
    drawerBGColor() {
      return this.option.drawerBGColor || "rgba(14,22,75,0.8)";
    },
    drawerTextColor() {
      return this.option.drawerTextColor || "#4383FF";
    },
    drawerWidth() {
      return (this.option.drawerWidth || 240) + "px";
    },
    contentFontSize() {
      return (this.option.contentFontSize || 16) + "px";
    },
  },
  created() {
    // console.log(this.$route.params.id)
    console.log(this.option.apiUrl);
    const apiUrl = this.option.apiUrl || "";
    if (apiUrl) {
      this.getMenuList(apiUrl);
    } else {
      this.getAllMenuList();
    }
  },
  methods: {
    // 获取menuList
    async getMenuList(apiUrl) {
      const { data } = (await customApi(apiUrl)) || {};
      const { result: res } = data || [];
      this.menuList = res;
      // console.log(res)
    },
    // 获取所有的menuList
    async getAllMenuList() {
      const params = {
        name: "",
      };
      const { data } = (await getAllList(params)) || {};
      const { result: res } = data || [];
      this.menuList = res;
      // console.log(res)
    },
    // 抽屉关闭
    handleClose() {
      this.drawer = !this.drawer;
    },
    // 抽屉打开
    handleOpen() {
      this.drawer = !this.drawer;
    },
    // 根据id获取menuList中对于的item
    getItem(id, list) {
      const that = this;

      function test(id, list) {
        list.forEach((item) => {
          if (item.id === id) {
            that.clickId = id;
            that.clickItem = item;
          } else {
            if (item.children && item.children.length) {
              test(id, item.children);
            }
          }
        });
      }

      test(id, list);
      return that.clickItem;
    },
    // menu选中事件，只有最后一级可选中
    handleMenuSelect(id, index) {
      console.log(id);
      console.log(index);
      console.log(this.getItem(id, this.menuList));
      const item = this.getItem(id, this.menuList);
      if (item.url) {
        location.href = item.url;
      }
    },
    // menu打开
    handleMenuOpen() {
      console.log(222);
    },
    // menu关闭
    handleMenuClose() {
      console.log(111);
    },
    // 返回
    goBack() {
      if (history.length > 1) {
        history.back();
      } else {
        window.close();
      }
    },
    goHome() {
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped lang="scss">
.back {
  height: 100%;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .button-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 20px;
    background-color: #08164a;

    img {
      margin-right: 4px;
    }
  }
}

.drawer-box {
  height: 100%;
  padding-top: 30px;

  h2 {
    //padding-top: 67px;
    padding-top: 36px;
    text-align: center;
    font-weight: normal;
  }

  ul {
    margin-top: 36px;
    text-align: center;

    li {
      line-height: 56px;
    }
  }
}

/deep/ .el-drawer {
  background-color: transparent;
}

///deep/ .el-drawer__body {
//  background-color: rgba(14, 22, 75, 0.8);
//}

/deep/ .el-submenu__title {
  text-align: left;
}

/deep/ .el-submenu__title:hover {
  background-color: deepskyblue !important;
}

/deep/ .el-menu-item {
  text-align: left;
}

/deep/ .el-menu-item:hover {
  background-color: deepskyblue !important;
}
</style>

