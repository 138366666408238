<template>
  <!--  3d饼图-->
  <div :style="{width: width, height: height}">
    <Base :id="id" :width=width :height="height" :option="options"></Base>
  </div>
</template>

<script>
import Base from './base'

export default {
  name: "hcPie3d",
  components: {Base},
  props: {
    id: String,
    component: Object,
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    width() {
      return (this.component.width || 600) + 'px'
    },
    height() {
      return (this.component.height || 400) + 'px'
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: 'pie',
          backgroundColor: 'rgba(0,0,0,0.01)',
          style: {
            "fontSize": "32px",
            color: '#ffffff'
          },
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
          }
        },
        title: {
          text: '3D 饼图',
          style: {
            "fontSize": "20px",
            "color": '#ffffff'
          }
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            dataLabels: {
              enabled: true,
              format: '{point.name}'
            }
          }
        },
        series: [{
          type: 'pie',
          name: '浏览器占比',
          data: [
            ['Firefox', 45.0],
            ['IE', 26.8],
            {
              name: 'Chrome',
              y: 12.8,
              // sliced: true,
              // selected: true
            },
            ['Safari', 8.5],
            ['Opera', 6.2],
            ['Others', 0.7]
          ]
        }]
      }
    }
  },
  watch: {
    option: {
      handler() {
        this.changeOptions()
      },
      deep: true
    },
    dataChart: {
      handler() {
        this.changeData()
      },
      deep: true
    }
  },
  created() {
    this.changeOptions()
    this.changeData()
  },
  methods: {
    // 参数改变
    changeOptions() {
      const value = this.option
      this.options.chart.backgroundColor = value.bgColor
      this.options.title.style.fontSize = (value.titleFontSize || 20) + 'px'
      this.options.title.style.color = value.titleColor || '#ffffff'
      this.options.chart.options3d.alpha = value.alpha || 50
      this.options.plotOptions.pie.depth = value.depth || 60
    },
    // 数据改变
    changeData() {
      this.options.series[0] = this.dataChart
      this.options = JSON.parse(JSON.stringify(this.options))
    }
  }
}
</script>

<style>

</style>
