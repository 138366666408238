<template>
  <div class="tabs-roll" v-if="option.backgroundImage">
    <div class="item-box"
         v-for="item in data"
         :key="item.value"
         @click="changeType(item)"
         :style="{
           'background-image': dataType === item.value ? 'url('+  option.empBackgroundImage +')':'url('+  option.backgroundImage +')',
           'height': itemHeight,
           'lineHeight': itemHeight,
           'fontSize': fontSize,
           'color': option.color}">
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabsVerticalRoll",
  props: {
    // backgroundImage: "/img/banner/banner4.png"
    // color: "#eee"
    // empBackgroundImage: "/img/banner/banner5.png"
    // empColor: "#4dffff"
    // fontSize: 20
    // split: 8
    // type: "tabs"
    option: {
      type: Object,
      default: () => {
        return {}
      }
    },
    child: {
      type: Object,
      default: () => {
        return {}
      }
    },
    component: {
      type: Object,
      default: () => {
        return {}
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    // dataType: {
    //   type: Number,
    //   default: 0
    // },
  },
  data() {
    return {
      // itemHeight: 20, // 一个item高
      dataType: 0
    }
  },
  computed: {
    itemHeight() {
      return (this.option.itemHeight || 40) + 'px'
    },
    fontSize() {
      return (this.option.fontSize || 20) + 'px'
    },
  },
  watch: {
    option: {
      handler(value) {
      },
      deep: true
    },
    child: {
      handler(value) {
      },
      deep: true
    },
    component: {
      handler(value) {
        this.computeItemHeight()
      },
      deep: true
    },
    data: {
      handler(value) {
        console.log('data-----------------------------')
        console.log(value)
      },
      deep: true
    },
    dataType: {
      handler(value) {
        console.log(value)
        console.log(this.data)

      },
      deep: true
    }
  },
  created() {
    this.dataType = this.data[0].value
    this.computeItemHeight()
    // console.log(this.$parent.$parent.nav)
    // console.log( window.__container.contain.nav[0].display=true)
  },
  methods: {
    computeItemHeight() {
      const contentHeight = this.component.height - 4 * this.data.length
      this.itemHeight = (contentHeight / this.data.length).toFixed(2)
    },
    changeType(item) {
      let prefix = item.value.split("-");
      let arrS = window.__container.contain.nav
      let all = arrS.filter(item => item.name.startsWith(prefix[0] + "-"))
      all.forEach(innerItem => {
        if (innerItem.name === item.value) {
          innerItem.display = false;
        } else {
          innerItem.display = true;
        }
      })
      console.log(all)

      // this.$emit("click", item.value)

      this.dataType = item.value
    }
  }
}
</script>

<style scoped>
.tabs-roll {
  overflow-y: auto;
}

.item-box {
  margin-bottom: 4px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
</style>
