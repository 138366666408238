<template>
  <!--  3D 漏斗图-->
  <div class="container" id="container">
    <div :id="id" :option="option" class="echarts-container"></div>
  </div>
</template>
<script>
import Highcharts from 'highcharts'
import Highcharts3d from 'highcharts/highcharts-3d';
import funnel3d from 'highcharts/modules/funnel3d';
import pyramid3d from 'highcharts/modules/pyramid3d';
import oldie from 'highcharts/modules/oldie';
import cylinder from 'highcharts/modules/cylinder';

Highcharts3d(Highcharts);
funnel3d(Highcharts);
pyramid3d(Highcharts);
oldie(Highcharts);
cylinder(Highcharts);
export default {
  props: {
    // id用于区分多处复用highcharts文件
    id: {
      type: String
    },
    //option 是图表的配置数据
    option: {
      type: Object
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      charts: null
    }
  },
  watch: {
    option: {
      handler() {
        this.setOption()
      },
      deep: true
    },
    width: {
      handler() {
        this.setOption()
      }
    },
    height: {
      handler() {
        this.setOption()
      }
    }
  },
  mounted() {
    // 页面加载完成之后再渲染图表
    this.setOption()
  },
  methods: {
    setOption() {
      const option = JSON.parse(JSON.stringify(this.option))
      if (this.charts) {
        this.charts.destroy()
      }
      this.charts = new Highcharts.Chart(this.id, option)
      this.charts.reflow()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 容器 */
::v-deep.container {
  width: 100%;
  height: 100%;

  .echarts-container {
    width: 100%;
    height: 100%;
  }

  // 去除水印
  .highcharts-credits {
    display: none;
  }
}
</style>
